<template>
  <Delete> </Delete>

  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="page_header">
    <div class="mv_cloud">
      <div class="right_area">
        <div class="img04">
          <img
            src="@/assets/front_component/images/assets/back02.png"
            alt="雲"
          />
        </div>
      </div>
      <div class="left_area">
        <div class="img01">
          <img
            src="@/assets/front_component/images/assets/back01.png"
            alt="雲"
          />
        </div>
      </div>
    </div>
    <div class="en_ttl en"><span>MY EVENT</span></div>
    <div class="content_wrap">
      <h2>My イベント</h2>
      <div class="mascot_wrap" style="display: none">
        <img
          src="@/assets/front_component/images/assets/meguru05.png"
          alt="めぐる君"
        />
      </div>
      <div
        class="user_img"
        :style="{ backgroundImage: 'url(' + profileImg + ')' }"
        v-if="profileImg"
      ></div>
      <div
        class="user_img"
        :style="{
          backgroundImage:
            'url(' +
            require('@/assets/front_component/images/test/test01.png') +
            ')',
        }"
        v-else
      ></div>
    </div>
  </div>
  <main>
    <div class="section_all_wrap">
      <div class="content_wrap">
        <div id="breadcrumb">
          <ul class="breadcrumb_list">
            <li><span>My イベント一覧</span></li>
          </ul>
        </div>
        <div class="content">
          <div class="content_in_wrap">
            <div class="innar">
              <div class="t_l">
                <div class="command mypage_clarea">
                  <router-link
                    :to="{ name: 'Control eventcreate' }"
                    class="create"
                    >新規追加</router-link
                  >
                </div>
              </div>
              <div class="js-scrollable">
                <table class="tbl_list">
                  <tr class="sort">
                    <th class="tbl_list_ttl">
                      <div class="sort_ttl"><span>タイトル</span></div>
                    </th>
                    <th class="tbl_list_day t_c">
                      <div class="select_list_wrap">
                        <ul class="select_list">
                          <li class="select_label-placeholder">
                            <span>投稿日</span>
                          </li>
                          <li class="select_items">
                            <ul class="select_options">
                              <li class="select_option">
                                <input
                                  v-model="order"
                                  class="select_input"
                                  type="radio"
                                  name="orderchange"
                                  id="order_desc"
                                  v-on:change="onOrderChange"
                                  value="desc"
                                />
                                <label class="select_label" for="order_desc"
                                  >新しい順</label
                                >
                              </li>

                              <li class="select_option">
                                <input
                                  v-model="order"
                                  class="select_input"
                                  type="radio"
                                  name="orderchange"
                                  id="order_asc"
                                  v-on:change="onOrderChange"
                                  value="asc"
                                />
                                <label class="select_label" for="order_asc"
                                  >古い順</label
                                >
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </th>
                    <th class="tbl_list_sta t_c">
                      <div class="select_list_wrap">
                        <ul class="select_list">
                          <li class="select_label-placeholder">
                            <span>ステータス</span>
                          </li>
                          <li class="select_items">
                            <ul class="select_options">
                              <li class="select_option">
                                <input
                                  v-model="status"
                                  class="select_input"
                                  type="radio"
                                  name="statuschange"
                                  id="status_all"
                                  v-on:change="onStatusChange"
                                  value="all"
                                />
                                <label class="select_label" for="status_all"
                                  >全て</label
                                >
                              </li>

                              <li class="select_option">
                                <input
                                  v-model="status"
                                  class="select_input"
                                  type="radio"
                                  name="statuschange"
                                  id="status_koukai"
                                  v-on:change="onStatusChange"
                                  value="公開"
                                />
                                <label class="select_label" for="status_koukai"
                                  >公開</label
                                >
                              </li>

                              <li class="select_option">
                                <input
                                  v-model="status"
                                  class="select_input"
                                  type="radio"
                                  name="statuschange"
                                  id="status_shitagaki"
                                  v-on:change="onStatusChange"
                                  value="下書き"
                                />
                                <label
                                  class="select_label"
                                  for="status_shitagaki"
                                  >下書き</label
                                >
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </th>
                    <th class="tbl_list_command">
                      <div class="sort_ttl"><span>操作</span></div>
                    </th>
                  </tr>
                  <tr v-for="event in events.slice()" :key="event.id">
                    <td class="tbl_list_ttl">
                      <router-link
                        :to="{
                          name: 'Control eventshow',
                          params: { id: event.id },
                          query: { row_count: now_post_num },
                        }"
                        >{{ event.title }}</router-link
                      >
                    </td>
                    <td class="tbl_list_day t_c">
                      <span class="day_time">
                        <img
                          src="@/assets/front_component/images/assets/board01.png"
                          alt="時間"
                        /><time
                          class="en"
                          :datetime="formatDate_time(event.created_at)"
                          >{{ formatDate(event.created_at) }}</time
                        >
                      </span>
                    </td>
                    <td class="tbl_list_sta t_c">{{ event.status }}</td>
                    <td class="tbl_list_command">
                      <div class="commandlist">
                        <div class="command">
                          <router-link
                            class="edit"
                            :to="{
                              name: 'Control eventedit',
                              params: { id: event.id },
                              query: { row_count: now_post_num },
                            }"
                            >編集</router-link
                          >
                        </div>
                        <div class="command">
                          <a
                            class="delete"
                            @click="deleteevent(event.id)"
                            data-tip="削除"
                            >削除</a
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
                <div id="load" style="display: none">
                  <div class="half-circle-spinner">
                    <div class="circle circle-1"></div>
                    <div class="circle circle-2"></div>
                  </div>
                </div>
                <div
                  id="more_btn"
                  class="t_c"
                  v-if="get_post_num < total && now_post_num < total"
                >
                  <div class="btn_wide">
                    <button v-on:click="eventListmore">さらに読み込む</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/bard01.png"
              alt="青鳥"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/bard02.png"
              alt="緑鳥"
            />
          </div>
        </div>
      </div>
      <div class="kusa_outline">
        <div class="kusa_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/kusa02.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/kusa05.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap09">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap10">
            <img
              src="@/assets/front_component/images/assets/kusa07.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap11">
            <img
              src="@/assets/front_component/images/assets/kusa06.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap12">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap13">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap14">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap15">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap16">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap17">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
        </div>
      </div>
      <div class="deco_outline">
        <div class="deco_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kikyu01.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/heri01.png"
              alt="ヘリコプター"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kikyu02.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kikyu03.png"
              alt="気球"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
  <div class="menu_wrap">
    <div class="menu_icon">
      <img
        src="@/assets/front_component/images/assets/menu01.png"
        alt="メニュー"
      />
    </div>
    <div class="menu_list_wrap">
      <div class="menu_ttl">作成する</div>
      <ul class="menu_list">
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'mysdgs' },
            }"
            >SDGsレポート</router-link
          >
        </li>
        <!-- <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'news' }}">お知らせ</router-link></li> -->
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'thanks' } }"
            >サンクス&ホメロンカード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'idea' } }"
            >アイデアカード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'daily' } }"
            >気づき日報</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'photolog' },
            }"
            >フォトログ</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'column' } }"
            >コラム</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import "@/assets/front_component/js/functions.js";
import event from "@/apis/Event";
import $ from "jquery";
import dayjs from "dayjs";
import user from "@/apis/User";
import ScrollHint from "scroll-hint";
import striptags from "striptags";
import Delete from "@/components/mypage_panel/Delete";

export default {
  name: "message",
  data() {
    return {
      now_post_num: 25,
      get_post_num: 25,
      total: "",
      order: "desc",
      status: "all",
      events: [],
      more_load: false,
      profileImg: false,
    };
  },
  components: {
    Delete,
  },
  watch: {
    events(val) {
      this.events = val;
    },
  },
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $(window).scrollTop(0);
    });
  },
  created() {
    this.get_post_num = this.$route.query.row_count
      ? parseInt(this.$route.query.row_count)
      : 25;

    this.eventList(this.get_post_num, this.$User.id, this.order, this.status);
    this.profileImgApi();
    if (localStorage.getItem("messageType") || localStorage.getItem("messagewithUsers")) {
      localStorage.removeItem("messageType"); 
      localStorage.removeItem("messagewithUsers"); 
    }
  },
  methods: {
    profileImgApi() {
      Api()
        .get("api/v1/files/users/" + this.$User.id + "?where=key@users")
        .then(
          (response) => {
            if (response != "") {
              let path = response.data.data[0].storage_file_name;
              let fileApiPass = "/api/v1/file/" + path;
              Api()
                .get(fileApiPass, { responseType: "arraybuffer" })
                .then(
                  (response) => {
                    let blob = new Blob([response.data], {
                      type: response.headers["content-type"],
                    });
                    let url = window.URL || window.webkitURL;
                    let src = url.createObjectURL(blob);
                    this.profileImg = src;
                    //console.log(this.profileImg);
                  },
                  (error) => {}
                );
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch(() => {});
    },
    formatDate: (dateStr) => dayjs(dateStr).format("YYYY.MM.DD"),
    formatDate_time: (dateStr) => dayjs(dateStr).format("YYYY-MM-DD"),
    deleteevent(eventid) {
      let vueThis = this;
      $("#confirmModal").addClass("open");

      $("#delete-btn").click(function () {
        $("#confirmModal").removeClass("open");
        $("#js-loader").fadeIn();

        event
          .delete(eventid)
          .then((response) => {
            if (response != null) {
              $("#js-loader").fadeOut();

              vueThis.events = vueThis.events.filter((item) => {
                return item.id != eventid;
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      });
    },
    eventList(num, id, order, status) {
      event
        .listNumUser(num, id, order, status)
        .then((response) => {
          if (response != null) {
            this.events = response.data.data;
            this.total = response.data.total;
            this.events.forEach((event_single) => {
              if (event_single.target_ids != null) {
                event_single.target_user = [];
                event_single.target_ids.forEach((user_id) => {
                  user
                    .show(user_id)
                    .then((response) => {
                      if (response != null) {
                        event_single.target_user.push(response.data.data);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    })
                    .finally(() => {});
                });
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    eventListmore() {
      $("#load").show();
      $("#more_btn").hide();

      this.now_post_num = this.now_post_num + this.get_post_num;

      event
        .listNumUser(this.now_post_num, this.$User.id, this.order, this.status)
        .then((response) => {
          this.more_load = true;
          if (response != null) {
            this.events = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    onOrderChange(event_order) {
      this.order = event_order.target.value;
      this.eventList(this.get_post_num, this.$User.id, this.order, this.status);
    },
    onStatusChange(event_status) {
      this.status = event_status.target.value;
      this.eventList(this.get_post_num, this.$User.id, this.order, this.status);
    },
  },
  mounted() {
    document.body.className = "page_control";
    this.$nextTick(function () {
      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
      new ScrollHint(".js-scrollable", {
        suggestiveShadow: true,
        i18n: {
          scrollable: "スクロールできます",
        },
      });
    });
  },
  updated() {
    if (this.more_load == true) {
      $("#load").hide();
      $("#more_btn").show();
      this.more_load = false;
    }
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
</style>
